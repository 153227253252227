@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap");

* {
  font-family: "Archivo", sans-serif;
}

a.link {
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  background: linear-gradient(0deg, white, white) no-repeat right bottom / 0
    var(--bg-h);
  transition: background-size 350ms;
  --bg-h: 100%;
  
  @media screen and (max-width: 768px) {
    color: #000000;
    background: linear-gradient(0deg, #000000, #000000) no-repeat right bottom /
      0 var(--bg-h);
  }

  &:where(:hover, :focus-visible) {
    background-size: 100% var(--bg-h);
    background-position-x: left;
  }

  padding-bottom: 2px;
  --bg-h: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  padding-inline-start: 0;
  margin: 0;
}

.container {
  width: 85%;
  margin: auto;
}

body {
  margin: 0;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000000;

  &::before {
    background-image: url("./../assets/images/bg.png");
    background-size: contain;
    content: "";
    display: block;
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: -1;
  }

  &::-webkit-scrollbar {
    width: 0.7em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12em;
    background-color: rgba(169, 169, 169, 0.419);
    outline: 1px solid slategrey;
  }
}

.hr {
  border-top: 1px solid #f4f4f4;
  margin: 1em 0;
}
