.Socials {
  display: flex;
  align-items: center;

  div {
    display: flex;
    width: fit-content;

    &:nth-of-type(2) {
      margin-left: 1.5em;
    }
  }
  // margin: 2em 0 4em;

  a {
    margin-left: 1.5em;
    background-color: #181818;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 25px;
      height: 25px;
    }

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    // margin: 1em auto 2em;
    flex-direction: column;

    div {
      margin: auto;

      &:nth-of-type(2) {
        margin: 1em auto 0;
      }
    }
  }
}

.Banner {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 1.2em;

  @media screen and (max-width: 768px) {
    height: 75px;
    object-fit: cover;
  }
}

.LinkBtn {
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid #ffffff;
  background-color: #e4212a;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 50px;
  padding: 1em 2em;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: fit-content;

  &:nth-of-type(even) {
    margin-left: 1em;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  &Green {
    background-color: #0c6e5f;
  }

  img {
    margin-left: 1em;
    width: 23px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 2em;
    font-size: 0.9em;
  }
}
