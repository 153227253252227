.About {
  padding-bottom: 1em;
  padding-top: 3em;

  @media screen and (max-width: 768px) {
    background-image: url("./../../assets/images/star.svg"),
      url("./../../assets/images/green-coil.svg"),
      url("./../../assets/images/yellow-coil.svg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 5em, 4em, 3em;
    background-position: 75% 8.2%, 108% 3%, 85% 36%;
    padding-top: 2em;
  }

  h1 {
    font-size: 5rem;
    font-weight: 500;
    width: 85%;
    // text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 2.4em;
    }
  }

  &Buttons {
    display: flex;
    margin: 4em 0;

    a {
      &:nth-of-type(1) {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 2em;
    }

    button {
      text-transform: uppercase;
      font-weight: 600;
      outline: none;
      border: 2px solid #ffffff;
      background-color: #e4212a;
      color: white;
      font-weight: bold;
      cursor: pointer;
      font-size: 1.2em;
      border-radius: 50px;
      padding: 1em 2em;
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-of-type(2) {
        background-color: #0c6e5f;
        margin-left: 1.5em;
      }

      img {
        margin-left: 1em;
        width: 23px;
      }

      @media screen and (max-width: 768px) {
        width: fit-content;
        font-size: 0.9em;

        &:nth-of-type(2) {
          margin-left: 0;
          margin-top: 1.2em;
        }
      }
    }
  }

  .BannerWrapper {
    margin: 5em 0 3em;
    position: relative;

    img {
      position: relative;
    }
  }

  section {
    margin-top: 2em;

    h2 {
      font-size: 2.5em;
      text-transform: uppercase;
      margin-bottom: 0.3em;
    }

    p,
    li {
      font-size: 1.2em;
      line-height: 1.5em;
    }
  }

  .Partners {
    margin-top: 0;
    margin-bottom: 10em;

    @media screen and (max-width: 768px) {
      margin-bottom: 3em;
    }

    h2 {
      text-align: center;
      margin-bottom: 2em;
    }

    &Logo {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1em;

      img {
        width: auto;
        max-width: 10em;
        margin: 2em auto;
        align-self: center;

        &.PartnersLogoRounded {
          border-radius: 12px !important;
        }

        @media screen and (max-width: 768px) {
          width: 7em;
          margin: 1em auto;
        }
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
