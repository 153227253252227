#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;

  h1 {
    font-size: 65px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0px;
    color: white;
    text-transform: uppercase;
  }

  h2 {
    font-size: 21px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    color: white;
  }

  p {
    color: white;
    font-weight: 400;
    margin: 1em 0;
  }

  a {
    display: inline-block;
    font-weight: 700;
    border-radius: 40px;
    text-decoration: none;
    color: white;
  }

  .notfound404 {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 140px;
    height: 140px;
    background-image: url("./../../assets/images/emoji.webp");
    background-size: cover;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-transform: scale(2.4);
      -ms-transform: scale(2.4);
      transform: scale(2.4);
      border-radius: 50%;
      background-color: #f2f5f89c;
      z-index: -1;
    }
  }
}
@media only screen and (max-width: 767px) {
  .notfound .notfound404 {
    width: 110px;
    height: 110px;
  }
  .notfound {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
}

@media only screen and (max-width: 600px) {
  .notfound {
    width: 80%;
  }
}
